<template>
    <CHeader :pHeaderProps="HeaderProps">
        <template slot="Contenido">
            <CLoader :pConfigLoad="ConfigLoad">
                <template slot="BodyFormLoad">
                    <div class="row" @click="InsertarServicio(1)">
                        <div class="col-12 col-sm-12 justify-content-center d-flex flex-column align-items-center">
                            <h2 class="mt-3 mb-3">Servicio con QR</h2>
                            <img class="img img-fluid img-qr" src="../../../images/tipoqr/QR_Code_example.png" alt="">
                        </div>
                    </div>
                    <div class="row mt-4" @click="InsertarServicio(2)">
                        <div class="col-12 col-sm-12 justify-content-center d-flex flex-column align-items-center">
                            <h2 class="mt-3 mb-3">Servicio sin QR</h2>
                            <img class="img img-fluid img-qr" src="../../../images/tipoqr/QR-codes-no-qr.png" alt="">
                        </div>
                    </div>
                </template>
            </CLoader>
        </template>
    </CHeader>
</template>

<script>
import CHeader from "@/views/webapp/components/HeaderWebApp.vue";
export default {
    props:["pServicio"],
    name:'TipoQR',
    components:{CHeader},
    data() {
        return {
            HeaderProps:{
                ShowSubHeader:false,
                ShowReturn:true,
                TextoHeader:'Selecciona Una Opción',
                ShowLogo:false,
            },
            ConfigLoad:{
				ShowLoader:false,
				ClassLoad:true,
			},
            Servicio:{},
            IdTrabajador:''
        }
    },
    methods: {
        ElegirQr(Tipo){
            this.ConfigLoad.ShowLoader=true;
            // navigator.permissions.query({name:"geolocation"}).then((result)=>{
            //     if (result.state==="granted" || result.state==="prompt") {
            //         // report(result.state);
            //         navigator.geolocation.getCurrentPosition((position)=>{
            //             let formData=new FormData();
            //             formData.set('IdTrabajador',this.IdTrabajador);
            //             formData.set('Estatus','Ocupado');
            //             formData.set('lat',position.coords.latitude);
            //             formData.set('lng',position.coords.longitude);
            //             this.$http.post('webapp/ubicacion',formData
            //             ).then((res)=>{
            //                 this.InsertarServicio(Tipo);
            //             }).catch((err)=>{
            //                 this.$toast.warning('Error de conexión, por favor inténtelo nuevamente');
            //             })
            //         },()=>{},{enableHighAccuracy: true,maximumAge:0,timeout: 10000})
            //     }
            //     else if (result.state==="denied") {
            //         // report(result.state);
            //         this.$toast.warning('Debe Aceptar los Permisos de Ubicación para Continuar');
            //         this.ConfigLoad.ShowLoader=false;
            //     }
            // })
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position)=>{
                        let formData=new FormData();
                        formData.set('IdTrabajador',this.Usuario.IdTrabajador);
                        formData.set('Estatus','Ocupado');
                        formData.set('lat',position.coords.latitude);
                        formData.set('lng',position.coords.longitude);
                        this.$http.post('webapp/ubicacion',formData
                        ).then((res)=>{
                            this.InsertarServicio(Tipo);
                        }).catch((err)=>{
                            this.$toast.warning('Error de conexión, por favor inténtelo nuevamente');
                        })
                    },()=>{},{enableHighAccuracy: true,maximumAge:0})
            }
            else{
                this.$toast.warning('Debe Aceptar los Permisos de Ubicación para Continuar');
                this.ConfigLoad.ShowLoader=false;
            }
        },
        InsertarServicio(Tipo){
		this.ConfigLoad.ShowLoader=true;
            let IdTrabajador=this.IdTrabajador;
            let Servicio=this.Servicio;
            let ParteCadena='';
                if (Tipo==1) {
                    ParteCadena=' Con QR';
                }
                else{
                    ParteCadena=' Sin QR';
                }
                this.$swal({
                    title: 'Iniciar Servicio',
                    text: '¿Desea iniciar este servicio'+ParteCadena+'?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No',
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                }).then((result)=>{
                    if(result.value){
                        if (navigator.geolocation) {
                            navigator.geolocation.getCurrentPosition((position)=>{
                                let formData=new FormData();
                                // console.log(Usuario);
                                formData.set('IdTrabajador',IdTrabajador);
                                formData.set('Estatus','Ocupado');
                                formData.set('IdServicio',Servicio.IdServicio);
                                formData.set('lat',position.coords.latitude);
                                formData.set('lng',position.coords.longitude);
                                formData.set('EstadoS','PENDIENTE');
                                formData.set('TipoQr',Tipo);
                                this.$http.post('webapp/setservicioestatus',formData
                                ).then((res)=>{
                                    this.$toast.success('Servicio iniciado');
                                    this.$router.push({name:'EquiposServicioWebApp',params:{pServicio:res.data.data.servicio}});
                                }).catch((err)=>{
                                    this.$toast.warning(err.response.data.message);
                                    // this.$toast.warning('Error de conexión, por favor inténtelo nuevamente');
                                })
                        },()=>{},{enableHighAccuracy: true,maximumAge:0})
                        }
                        else{
                            this.$toast.warning('Debe Aceptar los Permisos de Ubicación para Continuar');
                            this.ConfigLoad.ShowLoader=false;
                        }
                        // let formData=new FormData();
                        // formData.set('IdTrabajador',this.IdTrabajador);
                        // formData.set('IdServicio',this.Servicio.IdServicio);
                        // formData.set('EstadoS','PENDIENTE');
                        // formData.set('TipoQr',Tipo);
                        // formData.set('Estatus','Ocupado');
                        // this.$http.post(
                        //     'webapp/setservicioestatus',formData
                        // ).then((res)=>{
                        //     this.$toast.success('Servicio iniciado');
                        //     this.$router.push({name:'EquiposServicioWebApp',params:{pServicio:res.data.data.servicio}});
                        // }).catch((err)=>{
                        //     this.$toast.warning(err.response.data.message);
                        //     this.ConfigLoad.ShowLoader=false;
                        // });
                    }
		else{
			this.ConfigLoad.ShowLoader=false;
		}
                });
        },
        Regresar(){
            this.$router.push({name:'ServicioDetalleWebApp'});
        }
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$on('EmitReturn',()=>{
            this.Regresar();
        });
        if (this.pServicio!=undefined) {
            sessionStorage.setItem('servicio',JSON.stringify(this.pServicio));
        }
        this.Servicio=JSON.parse(sessionStorage.getItem('servicio'));
        this.IdTrabajador=JSON.parse(sessionStorage.getItem('user')).IdTrabajador;
    },
    mounted() {
        
    },
}
</script>

<style>

</style>
