<template>
    <div class="container-fluid">
        <Clist :regresar="Regresar"
        :Nombre="Titulo"
        @FiltrarC="Lista"
        :Filtro="Filtro"
        :isModal="EsModal"
        :pShowBtnAdd="false"
        :pConfigLoad="ConfigLoad"
        :isHistorialEquipo="true"
        :ShowPager="ShowPager"
        :ShowFilas="false">
            <template slot="DatosEquipo">
                <div class="col-lg-12 mb-3">
                    <div class="row">
                        <div class="col-lg-2">
                            <div class="avatar-upload-histequipo">
                                <div class="avatar-edit">
									<input id="ficha" @change="CambiarImagenTrabajador($event)" ref="ficha" type="file" name="ficha" accept=".png, .jpg, .jpeg">
									<label for="ficha"></label>
								</div>
                                <div v-if="BndImagenTrabajador!=0" class="avatar-edit3">
									<button type="button" @click="GuardarImagenTrabajador">
										<i class="fas fa-save"></i>
									</button>
								</div>
                                <div class="avatar-preview">
									<div id="imagePreviewFicha" :style="'background-image: url('+Trabajador.Foto2+');'" :src="Trabajador.Foto2">
									</div>
								</div>
                            </div>
                            <div v-if="BndImagenTrabajador!=0">
								<p class="text-danger text-leyenda">Aún no sube la imagen, presione guardar para subirla</p>
							</div>
                        </div>
                        <div class="col-lg-3">
                            <span class="titulosmonitoreo">Nombre: </span> <span class="text-info titulosmonitoreo">{{ Trabajador.Nombre }}</span>
							<br>
							<span class="titulosmonitoreo">Categoria: </span> <span class="text-info titulosmonitoreo">{{ Trabajador.Categoria }}</span>
							<br>
							<span class="titulosmonitoreo">Perfil: </span> <span class="text-info titulosmonitoreo">{{ Trabajador.Perfil }}</span>
							<br>
							<span class="titulosmonitoreo">Profesion:</span> <span class="text-info titulosmonitoreo">{{ Trabajador.Profesion }}</span>
							<br>
							<span class="titulosmonitoreo">Teléfono: </span> <span class="text-info titulosmonitoreo">{{ Trabajador.Telefono }}</span>
							<br>
							<span class="titulosmonitoreo">Usuario App:</span> <span class="text-info titulosmonitoreo">{{ Trabajador.Usuario }}</span>
							<br>
							<span class="titulosmonitoreo">Contraseña:</span> <span class="text-info titulosmonitoreo">{{ Trabajador.Pass }}</span>
							<br>
                        </div>
                        <div class="col-lg-2">
                            <span class="titulosmonitoreo">Sueldo Anual Integrado: </span> <span class="text-info titulosmonitoreo">{{ Trabajador.SueldoAnualIntegrado }}</span>
							<br>
							<span class="titulosmonitoreo">Mano de Obra / Hora: </span> <span class="text-info titulosmonitoreo">{{ Trabajador.CostoHora }}</span>
							<br>
							<span class="titulosmonitoreo">Horas de Trabajo Semanal: </span> <span class="text-info titulosmonitoreo">{{ Trabajador.HorasTS }}</span>
							<br>
							<span class="titulosmonitoreo">CheckIn:</span> <span class="text-info titulosmonitoreo">{{ DevolverHora(Trabajador.HoraEntrada,Trabajador.MinutoEntrada) }}</span>
							<br>
							<span class="titulosmonitoreo">CheckOut: </span> <span class="text-info titulosmonitoreo">{{ DevolverHora(Trabajador.HoraSalida,Trabajador.MinutoSalida) }}</span>
							<br>
							<span class="titulosmonitoreo">Comida:</span> <span class="text-info titulosmonitoreo">{{ Trabajador.MinutoComida == undefined || Trabajador.MinutoComida == '' ? 'Sin Horario Asignado' : Trabajador.MinutoComida + ' Minutos' }}</span>
							<br>
                        </div>
                        <div class="col-lg-2">
                            <span class="titulosmonitoreo">Fecha de Ingreso: </span> <span class="text-info titulosmonitoreo">{{Trabajador.FechaIngreso==null || Trabajador.FechaIngreso=='0000-00-00' ? 'Sin Fecha' : $getCleanDate(Trabajador.FechaIngreso,false)}}</span>
							<br>
							<span class="titulosmonitoreo">Fecha de Egreso: </span> <span class="text-info titulosmonitoreo">{{ Trabajador.FechaEgreso==null ? 'Activo' : $getCleanDate(Trabajador.FechaEgreso,false) }}</span>
							<br>
                        </div>
                        <div class="col-lg-3">
                            <div class="d-flex justify-content-end">
                                <button class="btn btn-01 mr-2" @click="EditarTrabajador(Trabajador.IdTrabajador)" data-toggle="modal" data-target="#ModalForm">Editar</button>
                                <button class="btn btn-danger" @click="BorrarTrabajador(Trabajador.IdTrabajador)">Eliminar</button>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-inline">
                                <button @click="CambiarPestania('Asistencia')" type="button" :class="Botones.Asistencia ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Asistencia</button>
                                <button @click="CambiarPestania('Comida')" type="button" :class="Botones.Comida ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Comida</button>
                                <button @click="CambiarPestania('Eventos')" type="button" :class="Botones.Eventos ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Eventos</button>
                                <button @click="CambiarPestania('Capacitaciones')" type="button" :class="Botones.Capacitaciones ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Capacitaciones</button>
                                <button @click="CambiarPestania('Material')" type="button" :class="Botones.Material ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Material de Trabajo</button>
                                <button @click="CambiarPestania('Vacaciones')" type="button" :class="Botones.Vacaciones ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Vacaciones y Permisos</button>
                                <button @click="CambiarPestania('Documentos')" type="button" :class="Botones.Documentos ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Documentos</button>
                                <button v-if="Trabajador.HomeOffice==1" @click="CambiarPestania('HomeOffice')" type="button" :class="Botones.HomeOffice ? 'btn btn-06 text-info bold m-0 pb-0 pr-2 pt-2' : 'btn btn-06 text-dark bold m-0 pb-0 pr-2 pt-2'">Ubicación Home Office</button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="Botones.Asistencia" slot="header">
                <tr>
                    <th>Fecha</th>
                    <th>Check In</th>
                    <th>Check Out</th>
                    <th>Comentario In</th>
                    <th>Comentario Out</th>
                    <th>Horas Extra</th>
                    <th>Estatus</th>
                    <th>Acciones</th>
                    <th>Observaciones</th>
                    <!-- <th>Cantidad</th> -->
                </tr>
            </template>
            <template v-if="Botones.Asistencia" slot="body">
                <tr v-for="(item,index) in ListaCheck" :key="index">
                    <td>{{ $getCleanDate(item.FechaReg,false) }}</td>
                    <td>{{ FormatearFecha(item.FechaReg) }}</td>
                    <td>{{ FormatearFecha(item.FechaCheckOut) }}</td>
                    <td>{{ $limitCharacters(item.Comentario,30) }}</td>
                    <td>{{ $limitCharacters(item.ComentarioCheckOut,30) }}</td>
                    <td>{{ item.CantidadHoraExtra }}</td>
                    <td>
                        <div class="d-flex justify-content-center">
                            <div v-b-tooltip.hover :title="item.Estatus" :class="DefinirClaseCheck(item.Estatus)"></div>
                        </div>
                    </td>
                    <td>
                        <button v-b-tooltip.hover.top title="Ver Comentario" @click="VerComentario(item,'In')" class="btn-icon mr-1" data-target="#Comentario" data-toggle="modal">
                            <i class="fa fa-eye"></i>
                        </button>
                        <button v-b-tooltip.hover.top title="Ver Ubicación Check-In" @click="VerUbicacion(item,'In')" class="btn-icon mr-1" data-target="#Ubicacion" data-toggle="modal">
                            <i class="far fa-map-marked-alt"></i>
                        </button>
                        <button v-b-tooltip.hover.top title="Ver Comentario" @click="VerComentario(item,'Out')" class="btn-icon mr-1" data-target="#Comentario" data-toggle="modal">
                            <i class="fa fa-eye"></i>
                        </button>
                        <button v-b-tooltip.hover.top title="Ver Ubicación Check-Out" @click="VerUbicacion(item,'Out')" class="btn-icon mr-1" data-target="#Ubicacion" data-toggle="modal">
                            <i class="far fa-map-marked-alt"></i>
                        </button>
                        <button v-b-tooltip.hover.top title="Cambiar Estatus" @click="AbrirCambioEstatus(item)" class="btn-icon" data-target="#Estatus" data-toggle="modal">
                            <i class="fa fa-pencil"></i>
                        </button>
                    </td>
                    <!-- <td>{{ item.HoraExtra == 1 ? 'Si' : 'No' }}</td> -->
                    <td>{{ $limitCharacters(item.MotivoEstatus,80) }}</td>
                </tr>
            </template>

            <template v-if="Botones.Comida || Botones.Eventos" slot="header">
                <tr>
                    <th>Fecha</th>
                    <th>In</th>
                    <th v-show="Botones.Eventos">Site</th>
                    <th>Out</th>
                    <th>Comentario</th>
                    <th>Acciones</th>
                </tr>
            </template>
            <template v-if="Botones.Comida || Botones.Eventos" slot="body">
                <tr v-for="(item,index) in ListaCheck" :key="index">
                    <td>{{ $getCleanDate(item.FechaReg,false) }}</td>
                    <td>{{ FormatearFecha(item.FechaReg) }}</td>
                    <td v-show="Botones.Eventos">{{ FormatearFecha(item.FechaSite) }}</td>
                    <td>{{ FormatearFecha(item.FechaCheckOut) }}</td>
                    <td>{{ $limitCharacters(item.Comentario,50) }}</td>
                    <td>
                        <button @click="VerComentario(item,'In')" class="btn-icon mr-1" data-target="#Comentario" data-toggle="modal">
                            <i class="fa fa-eye"></i>
                        </button>
                        <button v-b-tooltip.hover.top title="Ver Ubicación Check-In" @click="VerUbicacion(item,'In')" class="btn-icon mr-1" data-target="#Ubicacion" data-toggle="modal">
                            <i class="far fa-map-marked-alt"></i>
                        </button>
                        <button v-show="item.FechaSite!=null" v-b-tooltip.hover.top title="Ver Comentario" @click="VerComentario(item,'Site')" class="btn-icon mr-1" data-target="#Comentario" data-toggle="modal">
                            <i class="fa fa-eye"></i>
                        </button>
                        <button v-show="item.FechaSite!=null" v-b-tooltip.hover.top title="Ver Ubicación Site" @click="VerUbicacion(item,'Site')" class="btn-icon mr-1" data-target="#Ubicacion" data-toggle="modal">
                            <i class="far fa-map-marked-alt"></i>
                        </button>
                        <button v-show="item.FechaCheckOut!=null" v-b-tooltip.hover.top title="Ver Comentario" @click="VerComentario(item,'Out')" class="btn-icon mr-1" data-target="#Comentario" data-toggle="modal">
                            <i class="fa fa-eye"></i>
                        </button>
                        <button v-show="item.FechaCheckOut!=null" v-b-tooltip.hover.top title="Ver Ubicación Check-Out" @click="VerUbicacion(item,'Out')" class="btn-icon" data-target="#Ubicacion" data-toggle="modal">
                            <i class="far fa-map-marked-alt"></i>
                        </button>
                    </td>
                </tr>
            </template>

            <template v-if="Botones.Capacitaciones" slot="header">
                <tr>
                    <th>Fecha</th>
                    <th>Nombre</th>
                    <th>Comentario</th>
                    <th>Acciones</th>
                    <th>
                        <button @click="NuevaCapacitacion()" type="button" data-toggle="modal" data-target="#Capacitacion" class="btn-obs"><i class="fa fa-plus" title="Agregar Capacitacion"></i></button>
                    </th>
                </tr>
            </template>
            <template v-if="Botones.Capacitaciones" slot="body">
                <tr v-for="(item,index) in ListaCapacitacion" :key="index">
                    <td><i class="fa fa-calendar"></i> {{ $getCleanDate(item.Fecha,false) }}</td>
                    <td>{{ item.Nombre }}</td>
                    <td>{{ item.Comentario }}</td>
                    <td>
                        <Cbtnaccion 
                        :isModal="true" 
                        :pEmitSeccion="ConfigModalCapacitacion.EmitSeccion" 
                        :Id="item.IdCapacitacion"
                        :IrA="ConfigModalCapacitacion.Nombre">
                            <template slot="btnaccion">
                                <button @click="AbrirDocumento(item.Archivo)" class="btn-icon mr-2" title="Ver Documentación"><i class="fa fa-file-pdf"></i></button>
                            </template>
                        </Cbtnaccion>
                    </td>
                    <td ></td>
                </tr>
                <CSinRegistros :pContIF="ListaCapacitacion.length" :pColspan="5"></CSinRegistros>
            </template>

            <template v-if="Botones.Material" slot="header">
                <tr>
                    <th>Fecha</th>
                    <th>Nombre</th>
                    <th>Comentario</th>
                    <th>Acciones</th>
                    <th>
                        <button @click="NuevoMaterial()" type="button" data-toggle="modal" data-target="#MaterialTrabajo" class="btn-obs"><i class="fa fa-plus" title="Agregar Material de Trabajo"></i></button>
                    </th>
                </tr>
            </template>
            <template v-if="Botones.Material" slot="body">
                <tr v-for="(item,index) in ListaMaterial" :key="index">
                    <td><i class="fa fa-calendar"></i> {{ $getCleanDate(item.FechaReg,false) }}</td>
                    <td>{{ item.Nombre }}</td>
                    <td>{{ item.Comentario }}</td>
                    <td>
                        <Cbtnaccion 
                        :isModal="true" 
                        :pEmitSeccion="ConfigModalMaterialTrabajo.EmitSeccion" 
                        :Id="item.IdMaterialTrabajo"
                        :IrA="ConfigModalMaterialTrabajo.Nombre">
                            <template slot="btnaccion">
                                <button @click="AbrirDocumento(item.Archivo)" class="btn-icon mr-2" title="Ver Documentación"><i class="fa fa-file-pdf"></i></button>
                            </template>
                        </Cbtnaccion>
                    </td>
                    <td></td>
                </tr>
                <CSinRegistros :pContIF="ListaMaterial.length" :pColspan="5"></CSinRegistros>
            </template>

            <template v-if="Botones.Vacaciones" slot="header">
                <tr>
                    <th>Fecha</th>
                    <th>Fecha Inicio</th>
                    <th>Fecha Fin</th>
                    <th>Comentario</th>
                    <th>Acciones</th>
                    <th>
                        <button @click="NuevaVacacion()" type="button" data-toggle="modal" data-target="#Vacacion" class="btn-obs"><i class="fa fa-plus" title="Agregar Vacación"></i></button>
                    </th>
                </tr>
            </template>
            <template v-if="Botones.Vacaciones" slot="body">
                <tr v-for="(item,index) in ListaVacaciones" :key="index">
                    <td><i class="fa fa-calendar"></i> {{ $getCleanDate(item.FechaReg,false) }}</td>
                    <td><i class="fa fa-calendar"></i> {{ $getCleanDate(item.FechaInicio,false) }}</td>
                    <td><i class="fa fa-calendar"></i> {{ $getCleanDate(item.FechaFin,false) }}</td>
                    <td>{{ item.Comentario }}</td>
                    <td>
                        <Cbtnaccion 
                        :isModal="true" 
                        :pEmitSeccion="ConfigModalVacacion.EmitSeccion" 
                        :Id="item.IdVacacion"
                        :IrA="ConfigModalVacacion.Nombre">
                            <template slot="btnaccion">
                                <button @click="AbrirDocumento(item.Archivo)" class="btn-icon mr-2" title="Ver Documentación"><i class="fa fa-file-pdf"></i></button>
                            </template>
                        </Cbtnaccion>
                    </td>
                    <td ></td>
                </tr>
                <CSinRegistros :pContIF="ListaVacaciones.length" :pColspan="6"></CSinRegistros>
            </template>

            <template v-if="Botones.Documentos" slot="header">
                <tr>
                    <th>Fecha</th>
                    <th>Nombre</th>
                    <th>Comentario</th>
                    <th>Acciones</th>
                    <th>
                        <button @click="NuevoDocumento()" type="button" data-toggle="modal" data-target="#Documento" class="btn-obs"><i class="fa fa-plus" title="Agregar Documento"></i></button>
                    </th>
                </tr>
            </template>
            <template v-if="Botones.Documentos" slot="body">
                <tr v-for="(item,index) in ListaDocumentos" :key="index">
                    <td><i class="fa fa-calendar"></i> {{ $getCleanDate(item.FechaReg,false) }}</td>
                    <td>{{ item.Nombre }}</td>
                    <td>{{ item.Comentario }}</td>
                    <td>
                        <Cbtnaccion 
                        :isModal="true" 
                        :pEmitSeccion="ConfigModalDocumento.EmitSeccion" 
                        :Id="item.IdDocT"
                        :IrA="ConfigModalDocumento.Nombre">
                            <template slot="btnaccion">
                                <button @click="AbrirDocumento(item.Archivo)" class="btn-icon mr-2" title="Ver Documentación"><i class="fa fa-file-pdf"></i></button>
                            </template>
                        </Cbtnaccion>
                    </td>
                    <td ></td>
                </tr>
                <CSinRegistros :pContIF="ListaDocumentos.length" :pColspan="5"></CSinRegistros>
            </template>

            <template v-if="Botones.HomeOffice" slot="mapa">
                <div v-if="BndMostrarMapa" class="position-relative" style="bottom:30px;z-index:1000">
                    <h3>Seleccione la Ubicación Para Home Office</h3>
                    <Search :oLatLng="oLatLngHomeOffice" :Input="true" :pOrigen="'homeoffice'"></Search>
                    <br>
                    <div class="row d-flex justify-content-end">
                        <div class="col-12">
                            <button :disabled="BotonUbicacion.disableBtn" @click="GuardarUbicacion" class="btn btn-01">
                                <i v-show="!BotonUbicacion.disableBtn" class="fa fa-plus-circle"></i>
                                <i v-show="BotonUbicacion.disableBtn" class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> 
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </Clist>
        
        <Modal :size="ConfigModalTrabajador.Size" :Nombre="ConfigModalTrabajador.Nombre" :poBtnSave="oBtnSaveTrabajador">
			<template slot="Form">
				<Trabajador :poBtnSave="oBtnSaveTrabajador"></Trabajador>
			</template>
		</Modal>

        <Modal :pEmitSeccion="ConfigModalComentario.EmitSeccion" :NameModal="ConfigModalComentario.NombreSeccion"  :size="ConfigModalComentario.Size" :Nombre="ConfigModalComentario.Nombre" :poBtnSave="oBtnSaveComentario">
            <template slot="Form">
                <Comentario :NameList="ConfigModalComentario.NameForm" :poBtnSave="oBtnSaveComentario">
                </Comentario>
            </template>
        </Modal>
        
        <Modal :pEmitSeccion="ConfigModalUbicacion.EmitSeccion" :NameModal="ConfigModalUbicacion.NombreSeccion"  :size="ConfigModalUbicacion.Size" :Nombre="ConfigModalUbicacion.Nombre" :poBtnSave="oBtnSaveUbicacion">
            <template slot="Form">
                <Ubicacion :NameList="ConfigModalUbicacion.NameForm" :poBtnSave="oBtnSaveUbicacion">
                </Ubicacion>
            </template>
        </Modal>

        <Modal :pEmitSeccion="ConfigModalCapacitacion.EmitSeccion" :NameModal="ConfigModalCapacitacion.NombreSeccion"  :size="ConfigModalCapacitacion.Size" :Nombre="'Capacitación'" :poBtnSave="oBtnSaveCapacitacion">
            <template slot="Form">
                <Capacitacion :NameList="ConfigModalCapacitacion.NameForm" :poBtnSave="oBtnSaveCapacitacion">
                </Capacitacion>
            </template>
        </Modal>

        <Modal :pEmitSeccion="ConfigModalMaterialTrabajo.EmitSeccion" :NameModal="ConfigModalMaterialTrabajo.NombreSeccion"  :size="ConfigModalMaterialTrabajo.Size" :Nombre="'Material de Trabajo'" :poBtnSave="oBtnSaveMaterialTrabajo">
            <template slot="Form">
                <MaterialTrabajo :NameList="ConfigModalMaterialTrabajo.NameForm" :poBtnSave="oBtnSaveMaterialTrabajo">
                </MaterialTrabajo>
            </template>
        </Modal>

        <Modal :pEmitSeccion="ConfigModalVacacion.EmitSeccion" :NameModal="ConfigModalVacacion.NombreSeccion"  :size="ConfigModalVacacion.Size" :Nombre="'Vacaciones'" :poBtnSave="oBtnSaveVacacion">
            <template slot="Form">
                <Vacacion :NameList="ConfigModalVacacion.NameForm" :poBtnSave="oBtnSaveVacacion">
                </Vacacion>
            </template>
        </Modal>

        <Modal :pEmitSeccion="ConfigModalDocumento.EmitSeccion" :NameModal="ConfigModalDocumento.NombreSeccion"  :size="ConfigModalDocumento.Size" :Nombre="'Documentos'" :poBtnSave="oBtnSaveDocumento">
            <template slot="Form">
                <Documento :NameList="ConfigModalDocumento.NameForm" :poBtnSave="oBtnSaveDocumento">
                </Documento>
            </template>
        </Modal>

        <Modal :pEmitSeccion="ConfigModalEstatus.EmitSeccion" :NameModal="ConfigModalEstatus.NombreSeccion"  :size="ConfigModalEstatus.Size" :Nombre="'Cambio de Estatus'" :poBtnSave="oBtnSaveEstatus">
            <template slot="Form">
                <Estatus :NameList="ConfigModalEstatus.NameForm" :poBtnSave="oBtnSaveEstatus">
                </Estatus>
            </template>
        </Modal>
    </div>
</template>

<script>
import $$ from "jquery";
import ImagenDefault from "@/images/foto.png";
import Config from "@/helpers/VarConfig.js";
import Modal from '@/components/Cmodal.vue';
import Clist from "@/components/Clist.vue";
import Cbtnaccion from "@/components/Cbtnaccion.vue";
import CSinRegistros from "@/components/CSinRegistros";
import Trabajador from "@/views/catalogos/personal/Form.vue";
import Comentario from "./forms/Comentario.vue";
import Ubicacion from "./forms/Ubicacion.vue";
import Capacitacion from "./forms/Capacitacion.vue";
import MaterialTrabajo from "./forms/MaterialTrabajo.vue";
import Vacacion from "./forms/Vacacion.vue";
import Documento from "./forms/Documento.vue";
import Estatus from "./forms/CambioEstatus.vue";
export default {
    components:{
        Modal,
        Clist,
        Cbtnaccion,
        CSinRegistros,
        Comentario,
        Ubicacion,
        Capacitacion,
        MaterialTrabajo,
        Vacacion,
        Trabajador,
        Documento,
        Estatus
    },
    props:["Id"],
    data() {
        return {
            Titulo:"Recursos Humanos",
            Regresar:true,
            EsModal:true,
            Filtro:{
                Nombre: "",
				Placeholder: "Nombre..",
				TotalItem: 0,
				Pagina: 1,
				Entrada: 8,
				Show:true
            },
            ConfigLoad:{
                ShowLoader:true,
                ClassLoad:true
            },
            ShowPager:true,
            ConfigModalTrabajador:{
                NombreSeccion:"Trabajador",
                // EmitSeccion:"Trabajador",
                Size:"modal-lg",
                Nombre:"Trabajador",
                NameForm:"Trabajador"
            },
            oBtnSaveTrabajador:{
                isModal:true,
                disableBtn:false,
                toast:0,
                // nombreModal:"Trabajador",
                // EmitSeccion:"Trabajador"
            },
            ConfigModalComentario:{
                NombreSeccion:"Comentario",
                EmitSeccion:"Comentario",
                Size:"modal-md",
                Nombre:"Comentario",
                NameForm:"Comentario"
            },
            oBtnSaveComentario:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Comentario",
                EmitSeccion:"Comentario",
                ShowSave:false
            },
            ConfigModalUbicacion:{
                NombreSeccion:"Ubicacion",
                EmitSeccion:"Ubicacion",
                Size:"modal-lg",
                Nombre:"Ubicacion",
                NameForm:"Ubicacion"
            },
            oBtnSaveUbicacion:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Ubicacion",
                EmitSeccion:"Ubicacion",
                ShowSave:false
            },
            ConfigModalEstatus:{
                NombreSeccion:"Estatus",
                EmitSeccion:"Estatus",
                Size:"modal-md",
                Nombre:"Estatus",
                NameForm:"Estatus"
            },
            oBtnSaveEstatus:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Estatus",
                EmitSeccion:"Estatus"
            },
            ConfigModalCapacitacion:{
                NombreSeccion:"Capacitacion",
                EmitSeccion:"Capacitacion",
                Size:"modal-md",
                Nombre:"Capacitacion",
                NameForm:"Capacitacion"
            },
            oBtnSaveCapacitacion:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Capacitacion",
                EmitSeccion:"Capacitacion"
            },
            ConfigModalMaterialTrabajo:{
                NombreSeccion:"MaterialTrabajo",
                EmitSeccion:"MaterialTrabajo",
                Size:"modal-md",
                Nombre:"MaterialTrabajo",
                NameForm:"MaterialTrabajo"
            },
            oBtnSaveMaterialTrabajo:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"MaterialTrabajo",
                EmitSeccion:"MaterialTrabajo"
            },
            ConfigModalVacacion:{
                NombreSeccion:"Vacacion",
                EmitSeccion:"Vacacion",
                Size:"modal-md",
                Nombre:"Vacacion",
                NameForm:"Vacacion"
            },
            oBtnSaveVacacion:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Vacacion",
                EmitSeccion:"Vacacion"
            },
            ConfigModalDocumento:{
                NombreSeccion:"Documento",
                EmitSeccion:"Documento",
                Size:"modal-md",
                Nombre:"Documento",
                NameForm:"Documento"
            },
            oBtnSaveDocumento:{
                isModal:true,
                disableBtn:false,
                toast:0,
                nombreModal:"Documento",
                EmitSeccion:"Documento"
            },
            RutaImagen:"",
            ValidElement:Config.validImage2m,
            BndImagenTrabajador:0,
            ContadorImagenTrabajador:0,
            ObjImagenTrabajador:{},
            Trabajador:{
                IdTrabajador:'',
                Nombre:'',
                Teléfono:'',
                Profesión:'',
                Categoria:'',
                CostoHora:0,
                CostoAnual:0,
                IdSucursal:0,
                Usuario:'',
                Pass:'',
                Observaciones:'',
                Perfil:'',
                HorasTS:0,
                HorasPS:0,
                Imagen:'',
                Foto2:'',
                Correo:'',
                Estatus:'',
                AccesoApp:'',
                HoraEntrada:'',
                HoraSalida:'',
                HoraComida:'',
                HoraFinComida:'',
                AutHoraExtra:'',
                FechaReg:'',
                FechaDel:'',
                HomeOffice:0
            },
            Botones:{
                Asistencia:true,
                Comida:false,
                Eventos:false,
                Capacitaciones:false,
                Material:false,
                Vacaciones:false,
                Documentos:false,
                HomeOffice:false
            },
            oLatLngHomeOffice:{
                Lat: 19.43120339928868,
				Lng: -99.13450664719238
            },
            BndMostrarMapa:false,
            ListaCheck:[],
            ListaCapacitacion:[],
            ListaMaterial:[],
            ListaVacaciones:[],
            ListaDocumentos:[],
            Sucursal:{},
            BotonUbicacion:{
                disableBtn:false
            }
        }
    },
    methods: {
        async Lista(){
            this.Filtro.Show=true;
            this.ShowPager=true;
            this.ConfigLoad.ShowLoader=true;
            if (this.Botones.Asistencia || this.Botones.Comida || this.Botones.Eventos) {
                let TipoCheck='';
                if (this.Botones.Asistencia) {
                    TipoCheck='checkin';
                }
                if (this.Botones.Comida) {
                    TipoCheck='comida';
                }
                if (this.Botones.Eventos) {
                    TipoCheck='evento';
                }
                this.$http.get('checkindetalle/get',{
                    params:{
                        IdTrabajador:this.Trabajador.IdTrabajador,
                        TipoCheck:TipoCheck
                    }
                }).then((res)=>{
                    this.ListaCheck=res.data.data.tabla;
                    this.Sucursal=res.data.data.sucursal;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader=false;
                })
            }
            if (this.Botones.Capacitaciones) {
                this.$http.get('capacitacion/get',{
                    params:{
                        IdTrabajador:this.Trabajador.IdTrabajador,
                    }
                }).then((res)=>{
                    this.ListaCapacitacion=res.data.data.capacitaciones;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader=false;
                })
            }
            if (this.Botones.Material) {
                this.$http.get('materialtrabajo/get',{
                    params:{
                        IdTrabajador:this.Trabajador.IdTrabajador,
                    }
                }).then((res)=>{
                    this.ListaMaterial=res.data.data.materialestrabajo;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader=false;
                })
            }
            if (this.Botones.Vacaciones) {
                this.$http.get('vacacion/get',{
                    params:{
                        IdTrabajador:this.Trabajador.IdTrabajador,
                    }
                }).then((res)=>{
                    this.ListaVacaciones=res.data.data.vacaciones;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader=false;
                })
            }
            if (this.Botones.Documentos) {
                this.$http.get('docstrabajador2/get',{
                    params:{
                        IdTrabajador:this.Trabajador.IdTrabajador,
                    }
                }).then((res)=>{
                    this.ListaDocumentos=res.data.data.docstrabajador;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader=false;
                })
            }
            if (this.Botones.HomeOffice) {
                this.ConfigLoad.ShowLoader=false;
                // this.BndMostrarMapa=false;
                this.Filtro.Show=false;
                this.ShowPager=false;
                // await this.$http.get('clientesucursal/recovery',{
                //     params:{
                //         IdClienteS:this.IdClienteS
                //     }
                // }).then((res)=>{
                //     this.oLatLng.Lat=parseFloat(res.data.data.Clientes.Latitud);
                //     this.oLatLng.Lng=parseFloat(res.data.data.Clientes.Longitud);
                //     this.BndMostrarMapa=true;
                // });
            }
        },
        GetTrabajador(){
            this.BndMostrarMapa=false;
            this.$http.get("trabajador/recovery",{
                params:{
                    IdTrabajador: this.Trabajador.IdTrabajador
                }
            }).then((res)=>{
                this.Trabajador=res.data.data.trabajador;
                // this.oLatLngHomeOffice={
                //     lat:parseFloat(this.Trabajador.LatHome),
                //     lng:parseFloat(this.Trabajador.LngHome),
                // }
                if (this.Trabajador.HomeOffice==1 && (this.Trabajador.LatHome==null || this.Trabajador.LngHome==null)) {
                    // this.bus.$emit("actualCordenadas2");
                    navigator.geolocation.getCurrentPosition(position => {
                        this.oLatLngHomeOffice = {
                            Lat: position.coords.latitude,
                            Lng: position.coords.longitude
                        };
                    });
                }
                else{
                    this.oLatLngHomeOffice={
                        Lat:parseFloat(this.Trabajador.LatHome),
                        Lng:parseFloat(this.Trabajador.LngHome),
                    }
                }
                let imagn = res.data.data.trabajador.Imagen;
				let Fotgn = res.data.data.trabajador.Foto;
				let Fot2gn = res.data.data.trabajador.Foto2;
                if (imagn != "" && imagn != null) {
					this.Trabajador.Foto2 = "data:image/png;base64," + imagn;
				} else if (Fotgn != "" && Fotgn != null) {
					this.Trabajador.Foto2 = res.data.data.UrlFoto + this.Trabajador.Foto2;
				} else if (Fot2gn != "" && Fot2gn != null) {
					this.Trabajador.Foto2 = res.data.data.UrlFoto + this.Trabajador.Foto2;
				} else {
					this.Trabajador.Foto2 = ImagenDefault;
				}
				//this.Img= res.data.data.UrlFoto+ this.trabajador.Foto2;
				this.readURL(this.Trabajador.Foto2);
            }).finally(()=>{
                this.BndMostrarMapa=true;
            });
        },
        readURL(input) {
			$$("#imagePreviewFicha").css("background-image", "url(" + input + ")");
			$$("#imagePreviewFicha").hide();
			$$("#imagePreviewFicha").fadeIn(650);
		},
        CambiarPestania(index){
            let Botones=this.Botones;
			Object.keys(Botones).forEach(function(key,ind){
				if (key==index) {
					Botones[key]=true;
				}
				else{
					Botones[key]=false;
				}
				// console.log(index)
			});
            this.Filtro.Nombre="";
            this.Filtro.Pagina=1;
            this.Lista();
        },
        CambiarImagenTrabajador(target){
            if (this.ContadorImagenTrabajador==0) {
				this.$uploadImagePreview(target,this.ValidElement, Array('Img','imagePreviewFicha'));
				if(this.$refs.ficha.files[0]!=undefined){
					this.BndImagenTrabajador=1;
					this.ContadorImagenTrabajador++;
					this.ObjImagenTrabajador=this.$refs.ficha.files;
				}
				else{
					this.BndImagenTrabajador=0;
				}
			}
			else{
				this.$uploadImagePreview(target,this.ValidElement, Array('Img','imagePreviewFicha'));
				if(this.$refs.ficha.files[0]==undefined){
					this.$refs.ficha.files=this.ObjImagenTrabajador;
				}
				else{
					this.ObjImagenTrabajador=this.$refs.ficha.files;
				}
			}
        },
        GuardarImagenTrabajador(){
            // let RutaImagenAnterior=this.Trabajador.Foto2;
            let formData=new FormData();
			let image=this.$refs.ficha.files[0];
			if(image!=undefined){
				if (this.Trabajador.Foto2!=null) {
					let index=this.Trabajador.Foto2.lastIndexOf("/")+1;
					let cadena=this.Trabajador.Foto2.substr(index);	
					formData.set('FotoTrabajadorPrevio','/'+cadena);
				}
                formData.set('IdTrabajador',this.Trabajador.IdTrabajador);
				// formData.set('IdCliente',this.Contacto.IdCliente);
				formData.append('FotoTrabajador',image);
				// this.RutaImagen=image.name;
				// this.$http.post('crmcontactos/imagenexterior/post',formData,{
				// 	'Content-Type':'multipart/form-data'
				// })
                this.$http.post('trabajador/CambiarImagenTrabajador',formData,{
					'Content-Type':'multipart/form-data'
				})
                .then((res)=>{
                    // this.RutaImagen=RutaImagenAnterior;
					this.$toast.success("Imagen guardada");
					// this.bus.$emit('ActualizarImagenExterior',res.data.data.equipos.ImagenExterior);
					this.BndImagenTrabajador=0;
                    this.ContadorImagenTrabajador=0;
                    
				}).finally(() => {
                    this.GetTrabajador();
                });
			}
			else{
				this.$toast.info("Tienes que subir una imagen");
			}
        },
        DevolverHora(Hora,Minuto){
            if (Hora == '' || Minuto == '') {
                return 'Sin Horario Asignado';
            }
            if (parseInt(Minuto)<10) {
                Minuto='0'+Minuto;
            }
            return Hora+':'+Minuto;
        },
        FormatearFecha(Fecha){
            if (Fecha!=null) {
                let FechaObj=new Date(Fecha);
                let Hora=FechaObj.getHours();
                let Minuto=FechaObj.getMinutes() < 10 ? '0'+FechaObj.getMinutes() : FechaObj.getMinutes();
                return Hora+':'+Minuto;
            }
            else{
                return '-';
            }
        },
        DefinirClaseCheck(Status){
            let Clase="";
            switch (Status) {
                case "Puntual":
                    Clase="circuloStatusOperando";
                    break;
                case "Retardo":
                    Clase="circuloStatusObservacion";
                    break;
                case "Falta":
                    Clase="circuloStatusFueraServicio";
                    break;
                case "Hora Extra":
                    Clase="circuloStatusAzul";
                    break;
                case "Horas Extra":
                    Clase="circuloStatusAzul";
                    break;
                default:
                    Clase="circuloStatusDefault";
                    break;
            }
            return Clase;
        },
        EditarTrabajador(Index){
            this.bus.$emit("Nuevo",null,Index);
        },
        BorrarTrabajador(Id){
            this.$swal({
                    title: 'Esta seguro que desea eliminar este dato?',
                    text: 'No se podra revertir esta acción',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si',
                    cancelButtonText: 'No, mantener',
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                    }).then((result) => {
                    if(result.value) {
                         this.$http.delete(
                            'trabajador/' + Id
                        ).then( (res) => {
                            this.$toast.success('Información eliminada');
							this.bus.$emit("Regresar");
                            // this.Lista();
                        });
                    }
                });
        },
        EliminarCapacitacion(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'capacitacion/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.bus.$emit('List');
                        // this.ListaTodasPropiedades();
                    })
                }
            })
        },
        EliminarMaterial(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'materialtrabajo/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.bus.$emit('List');
                        // this.ListaTodasPropiedades();
                    })
                }
            })
        },
        EliminarVacacion(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'vacacion/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.bus.$emit('List');
                        // this.ListaTodasPropiedades();
                    })
                }
            })
        },
        EliminarDocumento(Id){
            this.$swal({
                title: 'Esta seguro que desea eliminar este dato?',
                text: 'No se podra revertir esta acción',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, mantener',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result)=>{
                if(result.value){
                    this.$http.delete(
                        'docstrabajador2/'+Id
                    ).then((res)=>{
                        this.$toast.success('Información eliminada');
                        this.bus.$emit('List');
                        // this.ListaTodasPropiedades();
                    })
                }
            })
        },
        VerComentario(Item,Tipo){
            if (Item.TipoCheck=='checkin') {
                if (Tipo=='In') {
                    this.ConfigModalComentario.Nombre='Check In';
                }
                else{
                    this.ConfigModalComentario.Nombre='Check Out';
                }
            }
            if (Item.TipoCheck=='comida') {
                this.ConfigModalComentario.Nombre='Comida';
            }
            if (Item.TipoCheck=='evento1' || Item.TipoCheck=='evento2' || Item.TipoCheck=='evento3') {
                this.ConfigModalComentario.Nombre='Eventos';
            }
            this.bus.$emit('NewModal_'+this.ConfigModalComentario.EmitSeccion,Item,Tipo);
        },
        VerUbicacion(Item,Tipo){
            this.bus.$emit('NewModal_'+this.ConfigModalUbicacion.EmitSeccion,Item,Tipo,this.Sucursal);
        },
        AbrirDocumento(Nombre){
            let archivo = Nombre;
			window.open(
				archivo,
				"_blank",
				"toolbar=1, scrollbars=1, resizable=1, width=" +
					1015 +
					", height=" +
					800
			);
        },
        NuevaCapacitacion(){
            this.bus.$emit('NewModal_'+this.ConfigModalCapacitacion.EmitSeccion,true,0,this.Trabajador.IdTrabajador);
        },
        NuevoMaterial(){
            this.bus.$emit('NewModal_'+this.ConfigModalMaterialTrabajo.EmitSeccion,true,0,this.Trabajador.IdTrabajador);
        },
        NuevaVacacion(){
            this.bus.$emit('NewModal_'+this.ConfigModalVacacion.EmitSeccion,true,0,this.Trabajador.IdTrabajador);
        },
        NuevoDocumento(){
            this.bus.$emit('NewModal_'+this.ConfigModalDocumento.EmitSeccion,true,0,this.Trabajador.IdTrabajador);
        },
        AbrirCambioEstatus(Item){
            this.bus.$emit('NewModal_'+this.ConfigModalEstatus.EmitSeccion,true,Item.IdCheck);
        },
        GuardarUbicacion(){
            this.BotonUbicacion.disableBtn=true;
            let formData=new FormData();
            formData.set('IdTrabajador',this.Trabajador.IdTrabajador);
            formData.set('LatHome',this.oLatLngHomeOffice.Lat);
            formData.set('LngHome',this.oLatLngHomeOffice.Lng);
            this.$http.post('trabajador/CambiarUbicacionHome',formData).then((res)=>{
                this.bus.$emit('List');
                this.BotonUbicacion.disableBtn=false;
                this.$toast.success('Ubicación Actualizada');
            }).catch((err)=>{
                this.BotonUbicacion.disableBtn=false;
                this.$toast.warning(err.res.data.message);
            });
        }
    },
    created() {
        this.Trabajador.IdTrabajador=this.Id;
        this.GetTrabajador();
        this.Lista();
        this.bus.$off('List');
        this.bus.$on('List',()=>{
            this.GetTrabajador();
        });
        this.bus.$off('ListaPrincipal');
        this.bus.$on('ListaPrincipal',()=>{
            this.Lista();
        });
        this.bus.$off('Delete_'+this.ConfigModalCapacitacion.EmitSeccion);
        this.bus.$on('Delete_'+this.ConfigModalCapacitacion.EmitSeccion,(Id)=>{
            this.EliminarCapacitacion(Id);
        });
        this.bus.$off('Delete_'+this.ConfigModalMaterialTrabajo.EmitSeccion);
        this.bus.$on('Delete_'+this.ConfigModalMaterialTrabajo.EmitSeccion,(Id)=>{
            this.EliminarMaterial(Id);
        });
        this.bus.$off('Delete_'+this.ConfigModalVacacion.EmitSeccion);
        this.bus.$on('Delete_'+this.ConfigModalVacacion.EmitSeccion,(Id)=>{
            this.EliminarVacacion(Id);
        });
        this.bus.$off('Regresar');
        this.bus.$on('Regresar',()=> 
        {
            this.$router.push({name:'checkin'});
        });
    },
}
</script>

<style>

</style>