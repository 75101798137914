<template>
    <div>
        <CLoader :pConfigLoad="ConfigLoad">
            <template slot="BodyFormLoad">
                <div class="row">
                    <div class="col-lg-4">
                        <label for="">Propiedad *</label>
                        <input type="text" disabled v-model="Propuesta.Propiedad" placeholder="Propiedad" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Propiedad" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-4">
                        <label for="">Ciudad *</label>
                        <input type="text" v-model="Propuesta.Ciudad" placeholder="Ciudad" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Ciudad" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-4">
                        <label for="">Numero *</label>
                        <input type="text" v-model="Propuesta.Numero" placeholder="Numero" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Numero" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <label for="">Nombre de la Propuesta *</label>
                        <input type="text" v-model="Propuesta.NombrePropuesta" placeholder="Nombre de la Propuesta" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.NombrePropuesta" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-6">
                        <label for="">Nombre del Equipo *</label>
                        <input type="text" v-model="Propuesta.NombreEquipo" placeholder="Nombre del Equipo" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.NombreEquipo" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <label for="">Valor *</label>
                        <!-- <input type="text" v-model="Propuesta.Valor" placeholder="Valor" class="form-control"> -->
                        <vue-numeric
                            class="form-control "
                            currency="$"
                            separator=","
                            placeholder="$0.00"
                            :precision="2"
                            v-model="Propuesta.Valor"
                        ></vue-numeric>
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Valor" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-3">
                        <label for="">Fecha *</label>
                        <v-date-picker v-model="Propuesta.Fecha" :mode="typeCalendar"
                            :popover="{
                                placement: 'bottom',
                                visibility: 'click'
                            }"
                            :input-props="{
                                class: 'form-control cal-black',
                                style: 'cursor:pointer;'
                            }"
                        />
                    </div>
                    <div class="col-lg-3">
                        <label for="">Vigencia *</label>
                        <input type="text" v-model="Propuesta.Vigencia" placeholder="Vigencia" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Vigencia" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                    <div class="col-lg-3">
                        <label for="">Urgencia *</label>
                        <input type="text" v-model="Propuesta.Urgencia" placeholder="Urgencia" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Urgencia" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <label for="">Archivo</label>
                        <div class="custom-file-input-image">
                            <input @change="SubirArchivo()" type="file" accept="application/pdf" ref="file" class="custom-file-input" id="validatedCustomFile" required>
                            <input type="text" v-model="Archivo" class="form-control">
                            <button type="button" class=""><i class="fas fa-paperclip"></i></button>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <label for="">Estatus *</label>
                        <input type="text" v-model="Propuesta.Estatus" placeholder="Estatus" class="form-control">
                        <label id="lblmsuser" style="color:red"><Cvalidation v-if="this.errorvalidacion.Estatus" :Mensaje="'Campo obligatorio'"></Cvalidation></label>
                    </div>
                </div>
            </template>
        </CLoader>
    </div>
</template>

<script>
export default {
    name:"Form",
    props:["poBtnSave","pIdClienteS","pContacto"],
    data() {
        return {
            Modal:true,
            FormName:'Propuestas',
            EmitSeccion:this.poBtnSave.EmitSeccion,
            ConfigLoad:{
                ShowLoader:true,
                ClassLoad:true
            },
            Propuesta:{
                IdPropuesta:0,
                IdCliente:0,
                IdClienteS:0,
                Propiedad:'',
                Ciudad:'',
                Numero:'',
                NombrePropuesta:'',
                NombreEquipo:'',
                Valor:'',
                Fecha:'',
                Vigencia:'',
                Urgencia:'',
                Archivo:'',
                Estatus:''
            },
            Archivo:'Elegir Archivo (3MB)',
            errorvalidacion:[]
        }
    },
    methods: {
        Guardar(){
            let Fecha='';
            if (this.Propuesta.Fecha!='' && this.Propuesta.Fecha!=undefined) {
                let day=this.Propuesta.Fecha.getDate();
                let month=this.Propuesta.Fecha.getMonth()+1;
                let year=this.Propuesta.Fecha.getFullYear();
                Fecha=year+'-'+month+'-'+day;   
            }
            let file = this.$refs.file.files[0];
            let formData=new FormData();
            formData.set('IdPropuesta',this.Propuesta.IdPropuesta);
            formData.set('IdCliente',this.Propuesta.IdCliente);
            formData.set('IdClienteS',this.Propuesta.IdClienteS);
            formData.set('Ciudad',this.Propuesta.Ciudad);
            formData.set('Numero',this.Propuesta.Numero);
            formData.set('NombrePropuesta',this.Propuesta.NombrePropuesta);
            formData.set('NombreEquipo',this.Propuesta.NombreEquipo);
            formData.set('Valor',this.Propuesta.Valor);
            formData.set('Fecha',Fecha);
            formData.set('Vigencia',this.Propuesta.Vigencia);
            formData.set('Urgencia',this.Propuesta.Urgencia);
            formData.set('NombreArchivo',this.Propuesta.Archivo);
            formData.set('Estatus',this.Propuesta.Estatus);
            formData.append('NombreArchivoFile',file);
            this.poBtnSave.toast=0;
            this.poBtnSave.disableBtn=true;
            this.$http.post('propuestas/post',formData,{
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            }).then((res)=>{
                this.poBtnSave.disableBtn=false; 
                this.poBtnSave.toast=1;
                $("#Propuesta").modal("hide");
                this.bus.$emit('List',"Crear","Propuesta");
            }).catch((err)=>{
                this.errorvalidacion=err.response.data.message.errores;
                this.poBtnSave.disableBtn=false;
                this.poBtnSave.toast=2;
            })
        },
        Limpiar(){
            const  input  = this.$refs.file;
            input .type = 'text'
            input .type = 'file';
            this.Propuesta={
                IdPropuesta:0,
                IdCliente:0,
                IdClienteS:0,
                Propiedad:'',
                Ciudad:'',
                Numero:'',
                NombrePropuesta:'',
                NombreEquipo:'',
                Valor:'',
                Fecha:'',
                Vigencia:'',
                Urgencia:'',
                Archivo:'Elegir Archivo (3MB)',
                Estatus:''
            }
            if (localStorage.getItem("fechacalendario")) {
				let NuevaFecha = localStorage.getItem("fechacalendario");
				let formatedDate = NuevaFecha.replace(/-/g, "\/");
				this.Propuesta.Fecha = new Date(formatedDate);
			}
            this.Archivo='Elegir Archivo (3MB)';
        },
        Recuperar(){
            this.$http.get('propuestas/recovery',{
                params:{
                    IdPropuesta:this.Propuesta.IdPropuesta
                }
            }).then((res)=>{
                const objres=res.data.data.propuesta;
                this.Propuesta.IdPropuesta=objres.IdPropuesta;
                this.Propuesta.IdCliente=objres.IdCliente;
                this.Propuesta.IdClienteS=objres.IdClienteS;
                this.Propuesta.Propiedad=objres.Propiedad;
                this.Propuesta.Ciudad=objres.Ciudad;
                this.Propuesta.Numero=objres.Numero;
                this.Propuesta.NombrePropuesta=objres.NombrePropuesta;
                this.Propuesta.NombreEquipo=objres.NombreEquipo;
                this.Propuesta.Valor=objres.Valor;
                let formatedDate = objres.Fecha.replace(/-/g, "\/");
                this.Propuesta.Fecha=new Date(formatedDate);
                this.Propuesta.Vigencia=objres.Vigencia;
                this.Propuesta.Urgencia=objres.Urgencia;
                this.Propuesta.Archivo=objres.Archivo;
                this.Propuesta.Estatus=objres.Estatus;
                this.Archivo=objres.Archivo;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader=false;
            })
        },
        SubirArchivo(){
            const image = this.$refs.file.files[0];

            var FileSize = image.size / 1024 / 1024; // in MB
            if (FileSize > 3) {
                this.$toast.info('Solo se puede subir archivos menores a 3 MB');
                const  input  = this.$refs.file;
                input .type = 'text'
                input .type = 'file';
                return false;
            }
                        
            var allowedExtensions = /(\.pdf|\.PDF)$/i;
            if(!allowedExtensions.exec(image.name)){
                this.$toast.info('Extenciones permitidas .pdf');
                const  input  = this.$refs.file;
                input.type = 'text'
                input.type = 'file';
                this.Archivo='Elegir archivo (3MB)';
                return false;
            }

            this.Archivo=image.name;
        }
    },
    created() {
        this.bus.$off("NewModal_"+this.EmitSeccion);
        this.bus.$on("NewModal_"+this.EmitSeccion,(data,Id,Propiedad,IdClienteS)=>{
            this.ConfigLoad.ShowLoader=true;
            this.poBtnSave.disableBtn=false;
            this.bus.$off("Save_"+this.EmitSeccion);
            this.bus.$on("Save_"+this.EmitSeccion, () => {
				this.Guardar();
			});
            this.Limpiar();
            if (Propiedad!==undefined) {
                this.Propuesta.Propiedad=Propiedad.Nombre;
                this.Propuesta.IdCliente=Propiedad.IdCliente;
                this.Propuesta.Ciudad=Propiedad.Ciudad;
            }
            if (IdClienteS!==undefined) {
                this.Propuesta.IdClienteS=IdClienteS;
            }
            if (Id > 0) {
				this.Propuesta.IdPropuesta = Id;
				this.Recuperar();
			}
            else{
                this.ConfigLoad.ShowLoader=false;
            }
        });
    },
    computed:{
        typeCalendar:function(){
            return 'single';
        }
    }
}
</script>

<style>

</style>